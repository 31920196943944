export interface TenantConfig {
	name: string;
	identifier: string;
	logo: string;
	url: string;
	utcOffset: string;
	dateFormat: string;
	timeFormat: string;
	currencySymbol: string;
	numberFormat: string;
	temperatureFormat: number;
	headerColour: string;
	footerColour: string;
	baseColour: string;
	primaryColour: string;
	secondaryColour: string;
	timeZone: string;
	backgroundColour: string;
}

interface TenantsConfig {
	[tenantName: string]: TenantConfig;
}

export const tenantsConfig: TenantsConfig = {
	dtw: {
		name: "Discover The World",
		identifier: "dtw",
		logo: "https://tprofileapp.blob.core.windows.net/efb776f1-0818-416c-c4fc-08d8224e5224/5c5f3f43-d83e-440f-46b8-08d8224e5226/discover-the-world-login-1.png",
		url: "https://discover-the-world.tprofile.com",
		utcOffset: "UTC ±00:00",
		dateFormat: "dd MMMM yyyy",
		timeFormat: "HH:mm",
		currencySymbol: "£",
		numberFormat: "N2",
		temperatureFormat: 0,
		primaryColour: "#72C300",
		secondaryColour: "#01274C",
		backgroundColour: "#EAEAE1",
		baseColour: "#72C300",
		footerColour: "#002A49",
		headerColour: "#002A49",
		timeZone: "GMT Standard Time",
	},
	njt: {
		name: "Not Just Travel",
		identifier: "njt",
		logo: "https://tprofile-app.azureedge.net/cf929560-fb67-4150-fa11-08dc4831b307/72a68e88-e51b-4fbe-351f-08dc4831b30c/logo-njt.jpg",
		url: "https://notjusttravel.tprofile.com",
		utcOffset: "UTC ±00:00",
		dateFormat: "dd MMMM yyyy",
		timeFormat: "HH:mm",
		currencySymbol: "£",
		numberFormat: "N2",
		temperatureFormat: 0,
		primaryColour: "#81385B",
		secondaryColour: "#64AFB3",
		backgroundColour: "#EAEAE1",
		baseColour: "#72C300",
		footerColour: "#002A49",
		headerColour: "#002A49",
		timeZone: "GMT Standard Time",
	},
};
