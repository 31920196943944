<template>
	<ion-page>
		<ion-tabs>
			<ion-router-outlet></ion-router-outlet>
			<ion-tab-bar slot="bottom" color="medium">
				<ion-tab-button v-for="(tab, index) in tabs" :key="index" :tab="tab.title" :href="tab.url" :class="tab.class" layout="icon-top">
					<div class="user-profile-icon-container" v-if="tab.title === 'Profile'">
						<img v-if="currentProfile?.profilePicture" :src="currentProfile.profilePicture" alt="" class="profile-icon" />
					</div>
					<custom-icon v-else-if="tab.customIcon" :name="tab.customIcon" class="tab-icon"> </custom-icon>
					<ion-label v-html="tab.title"></ion-label>
					<ion-badge v-if="tab.badgeCount > 0">{{ tab.badgeCount }}</ion-badge>
				</ion-tab-button>
			</ion-tab-bar>
		</ion-tabs>
		<ion-fab vertical="bottom" horizontal="center">
			<ion-fab-button :key="2" :href="tabs[2].url">
				<div class="fab-icon-mask"></div>
				<custom-icon :name="tabs[2].customIcon"></custom-icon>
			</ion-fab-button>
		</ion-fab>
	</ion-page>
</template>

<script lang="ts">
	import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonPage, IonBadge, IonRouterOutlet, IonFabButton, IonFab, loadingController, alertController, RefresherCustomEvent, onIonViewDidEnter } from "@ionic/vue";
	import {
		chatbubblesOutline,
		callOutline,
		mapOutline,
		mapSharp,
	} from "ionicons/icons";
	import { computed, ref, Ref, watch } from "vue";
	import { useRouter } from "vue-router";
	import { useStore } from "vuex";
	import CustomIcon from "@/components/CustomIcon.vue";
	import ProfileService from "@/services/ProfileService";
	import { Profile } from "@/classes/user/Profile";

	export default {
		name: "NavigationTabs",
		components: { IonBadge, IonLabel, IonTabs, IonTabBar, IonTabButton, IonPage, IonRouterOutlet, IonFabButton, IonFab, CustomIcon },
		setup() {
			const router = useRouter();
			const tabs = ref();
			const store = useStore();
			const currentRouteName = computed(() => router.currentRoute.value.name);
			const newMessageCount = computed(() => store.state.enquiries.newMessageCount);
			const newBookingCount = computed(() => store.state.bookings.newBookingCount);
			const newQuoteCount = computed(() => store.state.quotes.newQuoteCount);

			const currentProfile: Ref<Profile> = ref({
				id: "",
				externalId: "",
				applicationUserId: "",
				title: "",
				firstName: "",
				middleName: "",
				lastName: "",
				email: "",
				phone: "",
				phoneSecondary: "",
				dob: "",
				genderId: 0,
				aboutMe: "",
				profilePicture: "",
				createDate: "",
				lasyModifed: "",
				updateContactDetails: false,
				udpateAddresses: false,
				loyaltyPointsBalance: "",
				moreInfo: "",
			});

			const setupTabs = () => {
				tabs.value = [];

				tabs.value.push({
					title: "Home",
					url: router.resolve({ name: "mainmenu" }).href,
					customIcon: "icon-home",
					badgeCount: 0,
					class: "tab-home",
				});
				tabs.value.push({
					title: "Messages",
					url: router.resolve({ name: "list-messages" }).href,
					customIcon: "icon-messages",
					badgeCount: newMessageCount.value,
					class: "tab-messages",
				});
				tabs.value.push({
					title: "My Trips",
					url: router.resolve({ name: "my-trips" }).href,
					iosIcon: mapOutline,
					mdIcon: mapSharp,
					// customIcon: "icon-my-trips",
					customIcon: "transparent-icon",
					badgeCount: newQuoteCount.value,
					class: "tab-trips",
				});
				tabs.value.push({
					title: "Offers",
					url: router.resolve({ name: "bookings" }).href,
					customIcon: "icon-promotions",
					badgeCount: newBookingCount.value,
					class: "tab-offers",
				});

				tabs.value.push({
					title: "Profile",
					url: "/tabs/profile",
					customIcon: "profile-icon",
					badgeCount: 0,
					class: "tab-profile",
				});
			};

			const loadProfile = async () => {
				const loading = await loadingController.create({
					cssClass: "my-custom-class",
					message: "Loading profile",
				});
				loading.present();
				await ProfileService.getProfile().then(
					async (profile: Profile) => {
						currentProfile.value = profile;
						await loading.dismiss();
					},
					async (error) => {
						const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
						const alert = await alertController.create({
							cssClass: "my-custom-class",
							header: "Error",
							subHeader: "Unable to load your profile",
							message: message,
							buttons: ["OK"],
						});

						await loading.dismiss();
						await alert.present();
					}
				);
			};

			const doRefresh = async (event: RefresherCustomEvent) => {
				await loadProfile();
				event.target.complete();
			};

			onIonViewDidEnter(async () => {
				if (router.currentRoute.value.name != "login") {
					await await loadProfile();
				}
			});

			watch(currentRouteName, async () => {
				setupTabs();
			});
			watch(newMessageCount, async () => {
				setupTabs();
			});
			watch(newBookingCount, async () => {
				setupTabs();
			});
			watch(newQuoteCount, async () => {
				setupTabs();
			});

			setupTabs();

			return {
				tabs,
				chatbubblesOutline,
				callOutline,
				loadProfile,
				doRefresh,
				currentProfile,
			};
		},
	};
</script>