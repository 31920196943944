import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "user-profile-icon-container"
}
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_custom_icon = _resolveComponent("custom-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_tab_button = _resolveComponent("ion-tab-button")!
  const _component_ion_tab_bar = _resolveComponent("ion-tab-bar")!
  const _component_ion_tabs = _resolveComponent("ion-tabs")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab = _resolveComponent("ion-fab")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_tabs, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_router_outlet),
          _createVNode(_component_ion_tab_bar, {
            slot: "bottom",
            color: "medium"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.tabs, (tab, index) => {
                return (_openBlock(), _createBlock(_component_ion_tab_button, {
                  key: index,
                  tab: tab.title,
                  href: tab.url,
                  class: _normalizeClass(tab.class),
                  layout: "icon-top"
                }, {
                  default: _withCtx(() => [
                    (tab.title === 'Profile')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                          ($setup.currentProfile?.profilePicture)
                            ? (_openBlock(), _createElementBlock("img", {
                                key: 0,
                                src: $setup.currentProfile.profilePicture,
                                alt: "",
                                class: "profile-icon"
                              }, null, 8, _hoisted_2))
                            : _createCommentVNode("", true)
                        ]))
                      : (tab.customIcon)
                        ? (_openBlock(), _createBlock(_component_custom_icon, {
                            key: 1,
                            name: tab.customIcon,
                            class: "tab-icon"
                          }, null, 8, ["name"]))
                        : _createCommentVNode("", true),
                    _createVNode(_component_ion_label, {
                      innerHTML: tab.title
                    }, null, 8, ["innerHTML"]),
                    (tab.badgeCount > 0)
                      ? (_openBlock(), _createBlock(_component_ion_badge, { key: 2 }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(tab.badgeCount), 1)
                          ]),
                          _: 2
                        }, 1024))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1032, ["tab", "href", "class"]))
              }), 128))
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_fab, {
        vertical: "bottom",
        horizontal: "center"
      }, {
        default: _withCtx(() => [
          (_openBlock(), _createBlock(_component_ion_fab_button, {
            key: 2,
            href: $setup.tabs[2].url
          }, {
            default: _withCtx(() => [
              _cache[0] || (_cache[0] = _createElementVNode("div", { class: "fab-icon-mask" }, null, -1)),
              _createVNode(_component_custom_icon, {
                name: $setup.tabs[2].customIcon
              }, null, 8, ["name"])
            ]),
            _: 1
          }, 8, ["href"]))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}