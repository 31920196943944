import { tenantsConfig, TenantConfig } from "@/config/tenants-config";

const tenantName = process.env.VUE_APP_TENANT_NAME || "multi-tenant";

const tenantConfig: TenantConfig | undefined = tenantsConfig[tenantName];

if (!tenantConfig) {
	console.error(`Configuration for tenant "${tenantName}" not found.`);
	// Handle the error appropriately
}

export { tenantConfig };
